<template>
  <div
    class="Chunk"
    :class="{ hasFocus }">
    <!-- {{ focusIndex }} -->
    <div class="number">
      <span>{{ item.globalDigitStartIndex + 1 }}</span>
      <span v-if="item.size > 1">-{{ item.globalDigitStartIndex + item.size }}</span>
    </div>
    <!-- {{ item.globalDigitStartIndex }} -->
    <!-- {{ item.value }}- -->
    <div class="digits">
      <Digit
        v-for="(digit, i) of item.value"
        :key="i"
        class="Digit"
        :digit="digit"
        :focus="focusIndex === i"
        @click.native="select(i)" />
    </div>
    <div
      v-if="showingEditLayer"
      class="ValuePicker">
      <div
        v-for="i of 10"
        :key="i"
        class="Item"
        @click.stop="pickNumber(i - 1)">
        {{ i - 1 }}
      </div>
      <div
        class="Item"
        @click.stop="pickNumber('-')">
        -
      </div>
    </div>
  </div>
</template>

<script>
import Digit from './Digit';

export default {
    components: { Digit },
    props: {
        itemIndex: {
            type: Number
        },
        focusIndex: {
            type: Number
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    inject: ['module', 'transition', 'theme'],
    data() {
        return {
            showingEditLayer: false,
            digitIndex: 0
        };
    },
    computed: {
        item() {
            return this.module.state.quiz.items[this.itemIndex];
        },
        hasFocus() {
            return this.focusIndex !== -1;
        }
    },
    methods: {
        select(digitIndex) {
            this.$emit('request-focus', this.itemIndex, digitIndex);
        },
        // edit (digitIndex) {
        //   if (!this.editable) {
        //     return
        //   }
        //   this.showingEditLayer = true
        //   this.digitIndex = digitIndex
        // },
        // expand () {
        //   this.isExpanded = true
        // },
        pickNumber(number) {
            this.showingEditLayer = false;
            this.module.dispatch('quiz/setDigit', {
                itemIndex: this.itemIndex,
                digitIndex: this.digitIndex,
                value: '' + number
            });
        }
    }
};
</script>

<style scoped lang="scss">
.Chunk {
    position: relative;
    // margin: 0.5em;
    // padding: 1em;
    // background-color: rgba(white, 0.2);
    color: white;
    // border-radius: 0.5em;

    // display: flex;
    // align-items: center;
    &.hasFocus {
        // border: 1px solid white;
    }
    &:not(.hasFocus) {
        .number {
            opacity: 0;
        }
    }
}

.number {
    color: rgba(white, 0.3);
    font-size: 80%;
    margin-bottom: 0.5em;

    transition: opacity 0.5s;
}

.digits {
    display: flex;
    align-items: center;
}

.Digit {
    // width: 1.5em;
    // height: 2em;
    // background-color: gray;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    //
    // border-radius: 0.5em;

    &:not(:first-child) {
        margin-left: 4px; //0.2em;
    }
}

.ValuePicker {
    position: absolute;
    width: 2em;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    // height: 10em;

    background-color: white;
}
.Item {
    color: black;
    height: 2em;
}
</style>
