import { Machine } from 'xstate';

const memorizeStates = {
    initial: 'loading',
    onEntry: ['memorize/enter'],
    onExit: ['memorize/leave'],
    states: {
        loading: {
            on: {
                COUNTDOWN: 'countdown'
            }
        },
        countdown: {
            on: {
                PLAY: 'playing'
            }
        },
        playing: {
            onEntry: ['memorize/enterPage'],
            onExit: ['memorize/leavePage'],
            on: {
                PREV: {
                    playing: {
                        actions: ['memorize/prev']
                    }
                },
                NEXT: {
                    playing: {
                        actions: ['memorize/next']
                    }
                }
            }
        }
    }
};

const quizStates = {
    initial: 'loading',
    onEntry: ['quiz/enter'],
    onExit: ['quiz/leave'],
    states: {
        loading: {
            on: {
                COUNTDOWN: 'countdown'
            }
        },
        countdown: {
            on: {
                PLAY: 'playing'
            }
        },
        playing: {
            initial: 'page',
            onEntry: ['quiz/start'],
            onExit: ['quiz/stop'],
            on: {
                SUBMIT_ANSWER: {
                    feedback: { actions: ['quiz/submit'] }
                }
            },
            states: {
                page: {
                    onEntry: ['quiz/enterPage'],
                    onExit: ['quiz/leavePage'],
                    on: {
                        GOTO_PAGE: 'page',
                        GOTO_ALL: 'all'
                    }
                },
                all: {
                    onEntry: ['quiz/enterAll'],
                    onExit: ['quiz/leaveAll'],
                    on: {
                        GOTO_PAGE: 'page'
                    }
                }
            }
        },
        feedback: {
            on: {
                CONTINUE_PLAY: 'playing'
            }
        }
    }
};

const sessionStates = {
    initial: 'memorize',
    states: {
        memorize: {
            on: {
                START_QUIZ: 'quiz'
            },
            ...memorizeStates
        },
        quiz: {
            on: {
                LEVEL_FAIL: 'fail',
                LEVEL_SUCCEED: 'success'
            },
            ...quizStates
        },
        fail: {
            onEntry: ['quiz/fail']
        },
        success: {
            onEntry: ['quiz/success']
        }
    }
};

const machine = Machine({
    initial: 'initial',
    strict: true,
    states: {
        initial: {
            on: {
                START: 'session'
            }
        },
        session: {
            on: {
                EXIT_TO_INTRO: 'exit'
            },
            ...sessionStates
        },
        exit: {}
    }
});

export { machine };
