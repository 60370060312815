<template>
  <div
    class="DigitPad"
    :class="{ showDelete }">
    <template v-for="(row, rowIndex) of rows">
      <div
        :key="rowIndex"
        class="Row">
        <template v-for="(d, i) of row">
          <div
            v-if="d === '|'"
            :key="`${rowIndex}:${i}`"
            class="vertiLine" />
          <div
            v-else-if="d === '<'"
            :key="`${rowIndex}:${i}`"
            class="Digit"
            @click="$emit('click-digit', '<')">
            <svgicon
              class="arrow"
              icon="arrow-left" />
          </div>
          <div
            v-else-if="d === '>'"
            :key="`${rowIndex}:${i}`"
            class="Digit"
            @click="$emit('click-digit', '>')">
            <svgicon
              class="arrow"
              icon="arrow-right" />
          </div>
          <div
            v-else
            :key="`${rowIndex}:${i}`"
            class="Digit"
            @click="$emit('click-digit', d)">
            <span class="Digit__label">{{ d }}</span>
          </div>
        </template>
      </div>
      <div
        v-if="rowIndex < rows.length - 1 || showDelete"
        :key="rowIndex + 'b'"
        class="horiLine" />
    </template>

    <div
      v-if="showDelete"
      class="deleteButton"
      @click="$emit('click-digit', 'x')">
      Delete
    </div>
    <div
      v-if="showDelete"
      class="horiLine" />
  </div>
</template>

<script>
export default {
    props: {
        showDelete: {
            type: Boolean,
            default: false
        },
        left: {
            type: String,
            default: '<'
        },
        right: {
            type: String,
            default: '>'
        }
    },
    data() {
        return {
            rows: ['1|2|3', '4|5|6', '7|8|9', `${this.left}|0|${this.right}`]
        };
    },
    computed: {}
};
</script>

<style scoped lang="scss">
$color: rgba(white, 0.5);
.DigitPad {
    color: $color;
    height: 18em;
    display: flex;
    flex-direction: column;

    &:not(.showDelete) {
        .Row:last-child .vertiLine {
            background: linear-gradient(0deg, rgba(white, 0), rgba(white, 0.1));
        }
    }
}

.Row {
    display: flex;
    flex: 1;

    &:first-child .vertiLine {
        background: linear-gradcient(0deg, rgba(white, 0.1), rgba(white, 0));
    }
}
.Digit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    transition: background-color 0.5s;

    &:hover {
        color: white;
        .arrow {
            fill: white;
        }
    }
}
.Digit__label {
    font-size: 180%;
    font-weight: 300;
}
.vertiLine {
    align-self: stretch;
    width: 1px;
    background-color: rgba(white, 0.1);
}
.horiLine {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(white, 0), rgba(white, 0.1), rgba(white, 0));
}
.deleteButton {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        color: white;
    }
}

.arrow {
    $size: 1em;
    width: $size;
    height: $size;
    fill: $color;
}
</style>
