import last from 'lodash-es/last';
import gameHelper from '@/modules/games-shared/game-helper';

export default {
    namespaced: true,
    state() {
        return {
            sessionId: null,
            sourceItems: [],
            items: [],
            done: false,
            score: 0,
            lives: 3,
            start: 0,
            end: 0,
            pageIndex: 0,
            latestEnterTimestamp: 0,
            timeup: false,
            timeLimit: 0,
            timeUsed: 0
        };
    },
    actions: {
        async enter({ state, commit }) {
            commit('setLoading', true);
            const data = await gameHelper.sessions[state.sessionId].generateQuiz();
            commit('initialize', data);
            commit('setLoading', false);
        },
        leave({ state, commit }) {
            gameHelper.sessions[state.sessionId].reportQuiz({
                items: state.items,
                timeUsed: state.timeUsed,
                score: state.score,
                sourceItems: state.sourceItems
            });
        },
        start({ commit }) {
            commit('update', { start: Date.now() });
        },
        stop({ commit, state }) {
            const delta = Date.now() - state.start;
            commit('update', {
                timeUsed: state.timeUsed + delta,
                timeLimit: Math.ceil(state.timeLimit - delta / 1000)
            });
        },
        fail({ commit }) {
            // commit('update', { done: true, score: 0 })
        },
        success({ commit }) {
            // commit('update', { done: true, score: 1 })
        },

        enterPage({ commit }, { pageIndex }) {
            commit('update', { latestEnterTimestamp: Date.now() });
            if (pageIndex !== undefined) {
                commit('update', { pageIndex });
            }
        },
        leavePage({ state, commit }) {
            const itemIndex = state.pageIndex;
            const item = state.items[itemIndex];
            commit('updateItem', {
                itemIndex,
                data: { timeUsed: item.timeUsed + Date.now() - state.latestEnterTimestamp }
            });
        },
        enterAll({ state, commit }) {
            commit('update', { latestEnterTimestamp: Date.now() });
        },
        leaveAll({ state, commit }) {
            state.items.forEach((item, itemIndex) => {
                commit('updateItem', {
                    itemIndex,
                    data: { timeUsed: item.timeUsed + Date.now() - state.latestEnterTimestamp }
                });
            });
        },

        setDigit({ commit }, { itemIndex, digitIndex, value }) {
            commit('setDigit', { itemIndex, digitIndex, value });
        },
        submit({ commit, state }) {
            state.items.forEach((item, itemIndex) => {
                const value = item.value.join('');
                const score = value === item.fasit ? 1 : 0;
                const lastAttempt = last(item.attempts);
                if (!lastAttempt || lastAttempt.value !== value) {
                    const attempt = {
                        value,
                        score
                    };
                    commit('addAttempt', { itemIndex, attempt });
                    commit('updateItem', { itemIndex, data: { score } });
                }
            });
            const allCorrect = state.items.every(item => item.score);
            if (allCorrect) {
                commit('update', { done: true, score: 1 });
            } else {
                commit('update', { lives: state.lives - 1 });
            }
        }
    },
    mutations: {
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },
        setLoading(state, flag) {
            state.loading = flag;
        },
        initialize(state, data) {
            Object.assign(state, data);
        },
        update(state, data) {
            Object.assign(state, data);
        },
        updateItem(state, { itemIndex, data }) {
            Object.assign(state.items[itemIndex], data);
        },
        addAttempt(state, { itemIndex, attempt }) {
            state.items[itemIndex].attempts.push(attempt);
        },
        setDigit(state, { itemIndex, digitIndex, value }) {
            state.items[itemIndex].value.splice(digitIndex, 1, value);
            let newValue = state.items[itemIndex].value.slice();
            newValue[digitIndex] = value;
            state.items[itemIndex].value = newValue;
            // Vue.set(state.items[itemIndex], 'value', newValue)

            // Vue.set(state.items[itemIndex].value, digitIndex, value)
        }
    },
    getters: {
        numberOfDigits(state) {
            return state.items
                .map(item => item.value.length)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        },
        numberOfFilledDigits(state) {
            let cnt = 0;
            state.items.forEach(item => {
                cnt += item.value.filter(x => x !== '-').length;
            });
            // return state.items.map(item => item.value.filter(x => x !== '-').length).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            return cnt;
        }
    }
};
