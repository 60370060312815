<template>
  <div class="QuizView">
    <ProgressBar :progress="progress" />
    <GameHeader
      :show-lives="true"
      :countdown="countdown" />

    <div class="body">
      <EditAll
        v-if="showingEditLayer"
        @request-exit="hideAll()" />
      <SequentialInput
        v-else
        @request-editAll="showAll()" />
    </div>

    <div class="footer">
      <div
        xv-if="numberOfFilledDigits === numberOfDigits"
        class="doneButton --global-clickable"
        :class="{ disabled: progress < 1 }"
        @click="submit()">
        {{ $t('open') }}
      </div>
    </div>
  </div>
</template>

<translations>
  open: 'Open Vault'
  open_no: 'Åpne Safen'
</translations>

<script>
import timeoutMixin from '@/modules/games-shared/timeoutMixin';
import GameHeader from '@/modules/games-shared/components/GameHeader';
import ProgressBar from '@/modules/games-shared/components/ProgressBar';

import EditAll from '../components/EditAll';
import SequentialInput from '../components/SequentialInput';

export default {
    name: 'GameBankvaultQuizView',
    components: { GameHeader, ProgressBar, EditAll, SequentialInput },
    mixins: [timeoutMixin],
    inject: ['module', 'transition', 'theme'],
    data() {
        return {
            showingEditLayer: false,
            currentDigitIndex: 0,
            countdown: this.module.state.quiz.timeLimit
        };
    },
    computed: {
        session() {
            return this.module.state;
        },
        items() {
            return this.session.quiz.items;
        },
        currentItemIndex() {
            return this.session.quiz.pageIndex;
        },
        currentItem() {
            return this.items[this.currentItemIndex];
        },
        numberOfFilledDigits() {
            return this.module.getters('quiz/numberOfFilledDigits');
        },
        numberOfDigits() {
            return this.module.getters('quiz/numberOfDigits');
        },
        progress() {
            return this.numberOfFilledDigits / this.numberOfDigits;
        }
    },
    methods: {
        showAll() {
            this.showingEditLayer = true;
            this.transition('GOTO_ALL');
        },
        hideAll() {
            this.showingEditLayer = false;
            this.transition('GOTO_PAGE');
        },
        async submit() {
            if (this.progress < 1) {
                return;
            }
            this.transition('SUBMIT_ANSWER');
        },
        onTimeout() {
            this.module.commit('quiz/update', { timeup: true });
            if (this.numberOfFilledDigits === this.numberOfDigits) {
                this.transition('SUBMIT_ANSWER');
            } else {
                this.module.dispatch('quiz/submit');
                this.transition('LEVEL_FAIL');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.QuizView {
    display: flex;
    flex-direction: column;
}

.body {
    flex: 1;
    overflow: hidden; // Needed for some shrinking of children

    & > * {
        height: 100%;
    }
}

.doneButton {
    background-color: white;
    color: black;
    border-radius: 0.5em;
    padding: 1em 2em;
    text-align: center;

    &.disabled {
        cursor: default;
        opacity: 0.1;
    }
}

.footer {
    padding: 2em;
    flex-shrink: 0;
}
</style>
