<template>
  <div class="EditAll">
    <div @click="$emit('request-exit')">
      close
    </div>
    <div class="title">
      Select number to edit
    </div>
    <div class="xminiChunks">
      <Chunk
        v-for="(item, i) of items"
        :key="i"
        :item-index="i"
        :editable="true" />
    </div>
  </div>
</template>

<script>
import Chunk from '../components/Chunk';

export default {
    components: { Chunk },
    inject: ['module', 'theme'],
    props: {},
    computed: {
        session() {
            return this.module.state;
        },
        items() {
            return this.session.quiz.items;
        }
    }
};
</script>

<style scoped lang="scss">
.EditAll {
}
.title {
    text-align: center;
    font-size: 120%;
}
.miniChunks {
    display: flex;
    overflow-x: scroll;
}

.MiniChunk {
    margin: 0.5em;
}
</style>
