<template>
  <div
    class="AnimatedDigit"
    :class="{ resting }">
    <div>{{ digit % 10 }}</div>
  </div>
</template>

<script>
import { TweenLite, Linear } from 'gsap';

export default {
    props: {
        value: {
            //
        },
        trigger: {
            type: Number
        },
        delay: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            digit: 0,
            resting: false
        };
    },
    computed: {},
    watch: {
        trigger() {
            this.animate();
        }
    },
    mounted() {
        this.animate();
    },
    methods: {
        animate() {
            this.resting = false;
            let delta = this.value - this.digit + 10;
            if (delta < 10) {
                delta += 10;
            }

            TweenLite.to(this, 1, {
                digit: `+=${delta}`,
                roundProps: 'digit',
                ease: Linear.easeNone,
                delay: this.delay,
                onComplete: () => {
                    this.digit = this.digit % 10;
                    this.resting = true;
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.AnimatedDigit {
    position: relative;
    // margin: 0.5em;
    padding: 0.5em;
    // background-color: rgba(white, 0.2);
    color: rgba(white, 0.4);
    // border-radius: 0.5em;
    &.resting {
        color: #e2e59d;
    }
}
</style>
