<template>
  <div
    class="MemorizeView"
    :class="{ completed }">
    <ProgressBar :progress="progress" />
    <GameHeader
      class="header"
      :countdown="-1" />
    <div
      class="title"
      :style="{ color: theme.primaryColor }">
      {{ $t('title') }}
    </div>
    <div class="progressText">
      {{ currentItemIndex + 1 }}/{{ items.length }}
      <!-- -- {{ numberOfMemorizedDigits }}/{{ numberOfDigits }} -->
    </div>
    <br><br>
    <div class="main">
      <div class="wheelContainer">
        <svg
          class="wheel"
          viewBox="0 0 1484 1484"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
          <title>Wheel</title>
          <desc>Created with Sketch.</desc>
          <defs>
            <radialGradient
              id="radialGradient-1"
              cx="50%"
              cy="50%"
              fx="50%"
              fy="50%"
              r="93.6434659%"
              gradientTransform="translate(0.500000,0.500000),scale(0.999789,1.000000),rotate(-90.000000),translate(-0.500000,-0.500000)">
              <stop
                stop-color="#302E2E"
                offset="0%" />
              <stop
                stop-color="#585858"
                offset="100%" />
            </radialGradient>
          </defs>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <g
              id="Animation-Slide-Open-Copy-6"
              transform="translate(-117.000000, -762.000000)">
              <g
                id="Wheel"
                transform="translate(-177.000000, 468.000000)">
                <g
                  v-if="currentItem.data.ring1"
                  id="Big-Wheel"
                  ref="ring1">
                  <g
                    id="Big-Wheel-graphic"
                    transform="translate(1036.000000, 1036.000000) rotate(216.000000) translate(-1036.000000, -1036.000000) translate(295.000000, 295.000000)">
                    <circle
                      id="Outer-Circle"
                      fill-opacity="0.441972373"
                      fill="#1E1C1C"
                      transform="translate(741.000000, 741.000000) rotate(-270.000000) translate(-741.000000, -741.000000) "
                      cx="741"
                      cy="741"
                      r="741" />
                    <circle
                      id="Dark-Gray-Circle"
                      fill="#1E1C1C"
                      transform="translate(741.000000, 741.000000) rotate(-270.000000) translate(-741.000000, -741.000000) "
                      cx="741"
                      cy="741"
                      r="584" />
                    <circle
                      id="Inside-Circle"
                      fill="#2D2D2D"
                      opacity="0.649609375"
                      transform="translate(741.000000, 741.000000) scale(1, -1) rotate(-270.000000) translate(-741.000000, -741.000000) "
                      cx="741"
                      cy="741"
                      r="531" />
                    <g
                      id="measures"
                      transform="translate(245.000000, 277.000000)"
                      stroke="#484747"
                      stroke-width="4">
                      <path
                        id="Path-27"
                        d="M497.5,462.5 L649.5,0.5"
                        fill="#E9FF47" />
                      <path
                        id="Path-28"
                        d="M497.269531,462.048828 L344.728516,2.22070312" />
                      <path
                        id="Path-29"
                        d="M498.201172,461.425781 L990.891276,461.425781" />
                      <path
                        id="Path-30"
                        d="M498.169922,461.878906 L0.718156076,461.878906" />
                      <path
                        id="Path-31"
                        d="M498.222656,462.050781 L92.5097656,749.841797" />
                      <path
                        id="Path-32"
                        d="M498.039062,461.628906 L346.265625,923.896484" />
                      <path
                        id="Path-33"
                        d="M496.947266,461.619141 L651.951172,927.441406" />
                      <path
                        id="Path-34"
                        d="M497.287109,461.396484 L903.380859,750.052734" />
                      <path
                        id="Path-35"
                        d="M497.673828,461.582031 L91.4492188,175.650391" />
                      <path
                        id="Path-36"
                        d="M496.9375,460.775391 L900.939453,175.748047" />
                      <path
                        id="Path-37"
                        d="M497.529297,461.556641 L704.826172,67.1796875" />
                      <path
                        id="Path-38"
                        d="M499.322266,460.476562 L765.671875,104.427734" />
                      <path
                        id="Path-39"
                        d="M497.488281,461.466797 L818.166016,150.960938" />
                      <path
                        id="Path-40"
                        d="M497.398438,460.455078 L898.644531,260.710938" />
                      <path
                        id="Path-41"
                        d="M498.316406,460.517578 L925.683594,325.443359" />
                      <path
                        id="Path-42"
                        d="M497.652344,461.107422 L940.632812,394.933594" />
                      <path
                        id="Path-43"
                        d="M498.160156,460.007812 L566.40625,21.7148438" />
                      <path
                        id="Path-44"
                        d="M497.853516,461.501953 L497.853516,15.607267" />
                      <path
                        id="Path-45"
                        d="M498.501953,461.494141 L424.888672,23.2207031" />
                      <path
                        id="Path-46"
                        d="M497.667969,461.125 L296.273438,66.5449219" />
                      <path
                        id="Path-47"
                        d="M497.451172,460.904297 L233.888672,101.238281" />
                      <path
                        id="Path-48"
                        d="M497.533203,461.974609 L181.230469,146.681641" />
                      <path
                        id="Path-49"
                        d="M496.623047,461.544922 L100.255859,260.585938" />
                      <path
                        id="Path-50"
                        d="M496.412109,461.375 L72.0390625,325.316406" />
                      <path
                        id="Path-51"
                        d="M498.111328,461.619141 L59.6953125,394.900391" />
                      <path
                        id="Path-52"
                        d="M497.505859,460.517578 L58.0839844,528.462891" />
                      <path
                        id="Path-53"
                        d="M497.550781,460.787109 L73.0917969,597.345703" />
                      <path
                        id="Path-54"
                        d="M497.966797,461.513672 L99.6230469,663.160156" />
                      <path
                        id="Path-55"
                        d="M497.503906,460.560547 L181.34375,776.035156" />
                      <path
                        id="Path-56"
                        d="M497.056641,460.902344 L234.068359,823.167969" />
                      <path
                        id="Path-57"
                        d="M497.878906,461.535156 L295.095703,859.542969" />
                      <path
                        id="Path-58"
                        d="M498.226562,460.427734 L425.267578,895.060547" />
                      <path
                        id="Path-59"
                        d="M498.009766,461.097656 L498.009766,902.898438" />
                      <path
                        id="Path-60"
                        d="M496.753906,461.763672 L565.998047,895.748047" />
                      <path
                        id="Path-61"
                        d="M497.267578,461.373047 L701.248047,858.376953" />
                      <path
                        id="Path-62"
                        d="M497.060547,462.125 L765.035156,819.927734" />
                      <path
                        id="Path-63"
                        d="M497.046875,461.234375 L819.320312,773.519531" />
                      <path
                        id="Path-64"
                        d="M496.929688,461.96875 L899.167969,662.433594" />
                      <path
                        id="Path-65"
                        d="M497.794922,461.570312 L926.720703,597.84375" />
                      <path
                        id="Path-67"
                        d="M497.931641,461.597656 L942.167969,528.109375" />
                    </g>
                    <ellipse
                      id="Outer-Mask"
                      fill="url(#radialGradient-1)"
                      transform="translate(741.544785, 740.456945) rotate(-270.000000) translate(-741.544785, -740.456945) "
                      cx="741.544785"
                      cy="740.456945"
                      rx="415.544785"
                      ry="415.456945" />
                    <circle
                      id="background"
                      fill="#121010"
                      cx="740.432814"
                      cy="737.477545"
                      r="352" />
                  </g>
                  <g
                    id="Numbers"
                    transform="translate(1035.522831, 1037.507418) rotate(216.000000) translate(-1035.522831, -1037.507418) translate(309.022831, 335.007418)"
                    fill="#FFFFFF"
                    font-family="roboto"
                    font-size="62"
                    font-weight="normal">
                    <text
                      id="0"
                      transform="translate(925.369712, 78.759512) rotate(-252.000000) translate(-925.369712, -78.759512) ">
                      <tspan
                        x="907.017712"
                        y="95.7595118">0</tspan>
                    </text>
                    <text id="7">
                      <tspan
                        x="52.648"
                        y="717.5">7</tspan>
                    </text>
                    <text
                      id="6"
                      transform="translate(199.783506, 1090.868611) rotate(-36.000000) translate(-199.783506, -1090.868611) ">
                      <tspan
                        x="181.431506"
                        y="1107.86861">6</tspan>
                    </text>
                    <text
                      id="5"
                      transform="translate(518.988410, 1327.527060) rotate(-72.000000) translate(-518.988410, -1327.527060) ">
                      <tspan
                        x="500.63641"
                        y="1344.52706">5</tspan>
                    </text>
                    <text
                      id="4"
                      transform="translate(928.278729, 1325.209114) rotate(-108.000000) translate(-928.278729, -1325.209114) ">
                      <tspan
                        x="909.926729"
                        y="1342.20911">4</tspan>
                    </text>
                    <text
                      id="3"
                      transform="translate(1257.362255, 1088.075098) rotate(-144.000000) translate(-1257.362255, -1088.075098) ">
                      <tspan
                        x="1239.01025"
                        y="1105.0751">3</tspan>
                    </text>
                    <text
                      id="2"
                      transform="translate(1381.182918, 704.000000) rotate(-180.000000) translate(-1381.182918, -704.000000) ">
                      <tspan
                        x="1362.83092"
                        y="721">2</tspan>
                    </text>
                    <text
                      id="1"
                      transform="translate(1257.038507, 319.891031) rotate(-216.000000) translate(-1257.038507, -319.891031) ">
                      <tspan
                        x="1238.68651"
                        y="336.891031">1</tspan>
                    </text>
                    <text
                      id="9"
                      transform="translate(522.362444, 80.129059) rotate(-288.000000) translate(-522.362444, -80.129059) ">
                      <tspan
                        x="504.010444"
                        y="97.1290587">9</tspan>
                    </text>
                    <text
                      id="8"
                      transform="translate(196.489054, 318.715460) rotate(-324.000000) translate(-196.489054, -318.715460) ">
                      <tspan
                        x="178.137054"
                        y="335.71546">8</tspan>
                    </text>
                  </g>
                </g>
                <g
                  v-if="currentItem.data.ring2"
                  id="Small-Wheel"
                  ref="ring2"
                  transform="translate(616.000000, 617.000000)">
                  <g
                    id="Small-Wheel-Graphics"
                    transform="translate(69.000000, 67.000000)">
                    <circle
                      id="background"
                      fill="#121010"
                      cx="352"
                      cy="352"
                      r="352" />
                    <g
                      id="measures"
                      transform="translate(120.000000, 137.000000)"
                      stroke="#484747"
                      stroke-width="4">
                      <path
                        id="Path-27"
                        d="M232.286407,215.710246 L302.822355,0.176768359"
                        fill="#E9FF47" />
                      <path
                        id="Path-28"
                        d="M232,216 L161,1" />
                      <path
                        id="Path-29"
                        d="M232,215.5 L462,215.5" />
                      <path
                        id="Path-30"
                        d="M232,215.5 L0,215.5" />
                      <path
                        id="Path-31"
                        d="M232,216 L43,350" />
                      <path
                        id="Path-32"
                        d="M232,215 L161,431" />
                      <path
                        id="Path-33"
                        d="M232,215 L304,432" />
                      <path
                        id="Path-34"
                        d="M232,215 L421,350" />
                      <path
                        id="Path-35"
                        d="M232,215 L43,82" />
                      <path
                        id="Path-36"
                        d="M232,215 L420,82" />
                      <path
                        id="Path-37"
                        d="M232,215 L329,31" />
                      <path
                        id="Path-38"
                        d="M233,215 L357,49" />
                      <path
                        id="Path-39"
                        d="M232,215 L381,70" />
                      <path
                        id="Path-40"
                        d="M232,215 L419,122" />
                      <path
                        id="Path-41"
                        d="M232,215 L431,152" />
                      <path
                        id="Path-42"
                        d="M232,215 L439,184" />
                      <path
                        id="Path-43"
                        d="M232,215 L264,10" />
                      <path
                        id="Path-44"
                        d="M232.5,215 L232.5,7" />
                      <path
                        id="Path-45"
                        d="M232,215 L198,11" />
                      <path
                        id="Path-46"
                        d="M232,215 L138,31" />
                      <path
                        id="Path-47"
                        d="M232,215 L109,47" />
                      <path
                        id="Path-48"
                        d="M231,215 L84,68" />
                      <path
                        id="Path-49"
                        d="M232,216 L47,122" />
                      <path
                        id="Path-50"
                        d="M232,215 L34,152" />
                      <path
                        id="Path-51"
                        d="M232,215 L28,184" />
                      <path
                        id="Path-52"
                        d="M232,215 L27,247" />
                      <path
                        id="Path-53"
                        d="M232,215 L34,279" />
                      <path
                        id="Path-54"
                        d="M232,215 L46,309" />
                      <path
                        id="Path-55"
                        d="M232,215 L85,362" />
                      <path
                        id="Path-56"
                        d="M232,215 L109,384" />
                      <path
                        id="Path-57"
                        d="M233,215 L138,401" />
                      <path
                        id="Path-58"
                        d="M232,215 L198,418" />
                      <path
                        id="Path-59"
                        d="M232.5,215 L232.5,421" />
                      <path
                        id="Path-60"
                        d="M232,215 L264,417" />
                      <path
                        id="Path-61"
                        d="M232,215 L327,400" />
                      <path
                        id="Path-62"
                        d="M232,216 L357,383" />
                      <path
                        id="Path-63"
                        d="M232,215 L382,361" />
                      <path
                        id="Path-64"
                        d="M232,216 L420,310" />
                      <path
                        id="Path-65"
                        d="M232,215 L432,279" />
                      <path
                        id="Path-67"
                        d="M232,215 L439,246" />
                    </g>
                    <circle
                      id="Outer-Mask"
                      fill="#121010"
                      cx="352"
                      cy="354"
                      r="190" />
                  </g>
                  <g
                    id="Numbers"
                    transform="translate(421.325001, 420.512370) rotate(216.000000) translate(-421.325001, -420.512370) translate(117.825001, 121.512370)"
                    fill="#FFFFFF"
                    font-family="roboto"
                    font-size="42"
                    font-weight="normal">
                    <text
                      id="0"
                      transform="translate(380.820357, 37.056027) rotate(-252.000000) translate(-380.820357, -37.056027) ">
                      <tspan
                        x="368.388357"
                        y="49.0560265">0</tspan>
                    </text>
                    <text id="7">
                      <tspan
                        x="17.2017105"
                        y="315.372031">7</tspan>
                    </text>
                    <text
                      id="6"
                      transform="translate(86.229764, 465.453050) rotate(-36.000000) translate(-86.229764, -465.453050) ">
                      <tspan
                        x="73.7977643"
                        y="477.45305">6</tspan>
                    </text>
                    <text
                      id="5"
                      transform="translate(220.889177, 561.806882) rotate(-72.000000) translate(-220.889177, -561.806882) ">
                      <tspan
                        x="208.457177"
                        y="573.806882">5</tspan>
                    </text>
                    <text
                      id="4"
                      transform="translate(391.903175, 558.130883) rotate(-108.000000) translate(-391.903175, -558.130883) ">
                      <tspan
                        x="379.471175"
                        y="570.130883">4</tspan>
                    </text>
                    <text
                      id="3"
                      transform="translate(527.892755, 456.870491) rotate(-144.000000) translate(-527.892755, -456.870491) ">
                      <tspan
                        x="515.460755"
                        y="468.870491">3</tspan>
                    </text>
                    <text
                      id="2"
                      transform="translate(576.527117, 294.167153) rotate(-180.000000) translate(-576.527117, -294.167153) ">
                      <tspan
                        x="564.095117"
                        y="306.167153">2</tspan>
                    </text>
                    <text
                      id="1"
                      transform="translate(521.424521, 135.535704) rotate(-216.000000) translate(-521.424521, -135.535704) ">
                      <tspan
                        x="508.992521"
                        y="147.535704">1</tspan>
                    </text>
                    <text
                      id="9"
                      transform="translate(213.304704, 41.048362) rotate(-288.000000) translate(-213.304704, -41.048362) ">
                      <tspan
                        x="200.872704"
                        y="53.0483621">9</tspan>
                    </text>
                    <text
                      id="8"
                      transform="translate(79.348686, 143.165987) rotate(-324.000000) translate(-79.348686, -143.165987) ">
                      <tspan
                        x="66.9166864"
                        y="155.165987">8</tspan>
                    </text>
                  </g>
                </g>
                <polygon
                  v-if="currentItem.data.ring1"
                  id="Outer-Pointer"
                  fill="#FFFFFF"
                  transform="translate(311.000000, 1034.510925) scale(-1, 1) translate(-311.000000, -1034.510925) "
                  points="327 1024 295 1032.28134 327 1045.02185" />
                <path
                  v-if="currentItem.data.ring2"
                  id="Inner-Pointer"
                  d="M717,1025 C717,1025 706,1027.76045 684,1033.28134 L717,1046.02185 C717,1040.82446 717,1033.81717 717,1025 Z"
                  fill="#FFFFFF"
                  transform="translate(700.500000, 1035.510925) scale(-1, 1) translate(-700.500000, -1035.510925) " />
              </g>
            </g>
          </g>
        </svg>

        <div class="innerDigits">
          <template v-if="currentItem.data.inner1">
            <AnimatedDigit
              :value="currentItem.data.inner1[0]"
              :trigger="currentItemIndex"
              :delay="absoluteDelays.inner1" />
            <AnimatedDigit
              :value="currentItem.data.inner1[1]"
              :trigger="currentItemIndex"
              :delay="absoluteDelays.inner1" />
          </template>
          <template v-if="currentItem.data.inner2">
            <AnimatedDigit
              :value="currentItem.data.inner2[0]"
              :trigger="currentItemIndex"
              :delay="absoluteDelays.inner2" />
            <AnimatedDigit
              :value="currentItem.data.inner2[1]"
              :trigger="currentItemIndex"
              :delay="absoluteDelays.inner2" />
          </template>
        </div>
      </div>
    </div>

    <div class="footer">
      <transition
        name="out-left"
        mode="out-in">
        <ButtonWithCountdown
          :key="currentItemIndex"
          :time-limit="currentItem.timeLimit"
          @click="next()" />
      </transition>
    </div>
  </div>
</template>

<translations>
  title: 'Memorize numbers in the right order'
  title_no: 'Memorer tallene i riktig rekkefølge'
</translations>

<script>
import { TimelineMax, Power2 } from 'gsap';
import soundService from '@/services/sound-service';

import GameHeader from '@/modules/games-shared/components/GameHeader';
import ProgressBar from '@/modules/games-shared/components/ProgressBar';
import ButtonWithCountdown from '@/modules/games-shared/components/ButtonWithCountdown';

import AnimatedDigit from '../components/AnimatedDigit';

function calcRotation(oldRotation, newDigit) {
    let newRotation = -((360 / 10) * newDigit);
    if (newRotation === oldRotation) {
        newRotation += 360;
    }
    return newRotation;
}

export default {
    name: 'GameBankvaultMemorizeView',
    components: { GameHeader, AnimatedDigit, ButtonWithCountdown, ProgressBar },
    inject: ['module', 'transition', 'theme'],
    data() {
        return {
            itemTimeUsed: 0,
            completed: false
        };
    },
    computed: {
        memorizeSession() {
            return this.module.state.memorize;
        },
        items() {
            return this.memorizeSession.items;
        },
        currentItemIndex() {
            return this.memorizeSession.pageIndex; // NOTE: assumes 1 item per page
        },
        currentItem() {
            return this.items[this.currentItemIndex];
        },
        absoluteDelays() {
            let delay = 0;
            const map = {
                ring1: 0
            };
            if (this.currentItem.data.ring2) {
                delay += this.memorizeSession.delays.ring2;
                map.ring2 = delay;
            }
            if (this.currentItem.data.inner1) {
                delay += this.memorizeSession.delays.inner1;
                map.inner1 = delay;
            }
            if (this.currentItem.data.inner2) {
                delay += this.memorizeSession.delays.inner2;
                map.inner2 = delay;
            }
            return map;
        },
        numberOfDigits(state) {
            return this.memorizeSession.items
                .map(item => {
                    const x = item.data;
                    return (x.ring1 ? 1 : 0) + (x.ring2 ? 1 : 0) + (x.inner1 ? 2 : 0) + (x.inner2 ? 2 : 0);
                })
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        },
        numberOfMemorizedDigits(state) {
            return this.memorizeSession.items
                .slice(0, this.memorizeSession.itemIndex)
                .map(item => {
                    const x = item.data;
                    return (x.ring1 ? 1 : 0) + (x.ring2 ? 1 : 0) + (x.inner1 ? 2 : 0) + (x.inner2 ? 2 : 0);
                })
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        },
        progress() {
            return this.completed ? 1 : this.currentItemIndex / this.items.length;
        }
    },
    watch: {
        currentItemIndex(newIndex) {
            this.animate();
        }
    },
    mounted() {
        this.ring1Rotation = 0;
        this.ring2Rotation = 0;
        soundService.play(this.theme.sounds.themeSound, { volume: 0.5, loop: true }, 'background');
        this.animate();
    },
    beforeDestroy() {
        if (this.tl) {
            this.tl.kill();
        }
        soundService.stop(this.theme.sounds.themeSound, 500);
        clearTimeout(this.completionTimeoutId);
    },
    methods: {
        animate() {
            let tl = (this.tl = new TimelineMax({
                onComplete: () => {
                    //
                }
            }));
            if (this.currentItem.data.ring1) {
                this.$refs.ring1.classList.remove('resting');
                if (this.$refs.ring1.querySelector('.active')) {
                    this.$refs.ring1.querySelector('.active').classList.remove('active');
                }
                this.$refs.ring1.querySelector(`[id="${this.currentItem.data.ring1}"]`).classList.add('active');
                this.ring1Rotation = calcRotation(this.ring1Rotation, parseInt(this.currentItem.data.ring1, 10));
                tl.to(
                    this.$refs.ring1,
                    1,
                    {
                        rotation: this.ring1Rotation + 36,
                        transformOrigin: 'center center',
                        ease: Power2.easeInOut,
                        onComplete: () => {
                            this.$refs.ring1.classList.add('resting');
                        }
                    },
                    this.absoluteDelays.ring1
                );
            }
            if (this.currentItem.data.ring2) {
                this.$refs.ring2.classList.remove('resting');
                if (this.$refs.ring2.querySelector('.active')) {
                    this.$refs.ring2.querySelector('.active').classList.remove('active');
                }
                this.$refs.ring2.querySelector(`[id="${this.currentItem.data.ring2}"]`).classList.add('active');

                this.ring2Rotation = calcRotation(this.ring2Rotation, parseInt(this.currentItem.data.ring2, 10));
                tl.to(
                    this.$refs.ring2,
                    1,
                    {
                        rotation: this.ring2Rotation + 36,
                        transformOrigin: 'center center',
                        ease: Power2.easeInOut,
                        onComplete: () => {
                            this.$refs.ring2.classList.add('resting');
                        }
                    },
                    this.absoluteDelays.ring2
                );
            }
            tl.play();
        },
        prev() {
            if (this.session.memorizeSession) {
                return;
            }
            this.transition('PREV');
        },
        next() {
            if (this.currentItemIndex === this.items.length - 1) {
                this.completed = true;
                this.completionTimeoutId = setTimeout(() => {
                    this.transition('START_QUIZ');
                }, 1000);
                return;
            }
            this.transition('NEXT');
        }
    }
};
</script>

<style scoped lang="scss">
.MemorizeView {
    display: flex;
    flex-direction: column;

    transition: opacity 1s;

    &.completed {
        opacity: 0;
    }
}
.countdown {
    flex-shrink: 0;
}
.title {
    text-align: center;
    font-size: 120%;
}
.progressText {
    margin-top: 0.5em;
    color: rgba(white, 0.5);
    text-align: center;
}

.main {
    flex: 1;
    align-self: stretch;
    position: relative;
}
.wheelContainer {
    $size: 30em;
    position: absolute;
    width: $size;
    height: $size;

    right: -$size / 4;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wheel {
    position: absolute;
    width: 100%;
    height: 100%;
}
#Big-Wheel #Numbers {
    fill: mix(white, black, 30%);
}
#Big-Wheel.resting #Numbers {
    .active {
        fill: #e2e59d;
    }
}
#Small-Wheel #Numbers {
    fill: mix(white, black, 30%);
}
#Small-Wheel.resting #Numbers {
    .active {
        fill: #e2e59d;
    }
}
.digits {
    display: flex;
    flex-wrap: wrap;
}
.Digit {
    margin: 0.5em;
    padding: 1em;
    background-color: rgba(white, 0.2);
    color: white;
    border-radius: 0.5em;
}

.innerDigits {
    display: flex;
}

.spacer {
    flex: 1;
}

.footer {
    padding: 1em 0em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
}
.ButtonWithCountdown {
    $transitionDuration: 0.5s;

    &.out-left-enter-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }
    &.out-left-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }

    &.out-left-enter {
        transform: translate3d(1em, 0, 0);
        // transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
    &.out-left-leave-to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        // transform: translate3d(0, 100%, 0);
    }
}
</style>
