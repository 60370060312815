<template>
  <div class="SequentialInput">
    <div
      class="title"
      :style="{ color: theme.primaryColor }">
      {{ $t('title') }}
    </div>

    <div>
      <div
        ref="container"
        class="container">
        <div class="padding" />
        <div class="chunks">
          <Chunk
            v-for="(item, i) of items"
            :key="i"
            :data-item-index="i"
            :item-index="i"
            :focus-index="currentItemIndex === i ? currentDigitIndex : -1"
            @request-focus="onRequestFocus" />
        </div>
        <div class="padding" />
      </div>
      <br>
      <div class="showAllContainer" />
    </div>
    <div class="spacer" />
    <DigitPad
      class="DigitPad"
      @click-digit="setDigit" />
  </div>
</template>

<translations>
  title: 'Unlock the safe'
  title_no: 'Åpne safen'
</translations>

<script>
import { TweenLite } from 'gsap';
import DigitPad from '@/modules/games-shared/components/DigitPad';
import Chunk from '../components/Chunk';

export default {
    components: { Chunk, DigitPad },
    inject: ['module', 'theme', 'transition'],
    props: {},
    data() {
        return {
            currentDigitIndex: 0
        };
    },
    computed: {
        session() {
            return this.module.state;
        },
        items() {
            return this.session.quiz.items;
        },
        currentItemIndex() {
            return this.session.quiz.pageIndex;
        },
        currentItem() {
            return this.items[this.currentItemIndex];
        },

        numberOfFilledDigits() {
            return this.module.getters('quiz/numberOfFilledDigits');
        },
        numberOfDigits() {
            return this.module.getters('quiz/numberOfDigits');
        }
    },
    mounted() {
        this.selectDigit(0);
    },
    methods: {
        onRequestFocus(chunkIndex, digitIndex) {
            this.selectChunk(chunkIndex);
            this.selectDigit(digitIndex);
        },
        selectChunk(index) {
            this.transition('GOTO_PAGE', { pageIndex: index });
        },
        selectDigit(localDigitIndex) {
            this.currentDigitIndex = localDigitIndex;

            const containerBox = this.$refs.container.getBoundingClientRect();
            const chunkBox = this.$refs.container
                .querySelector(`[data-item-index="${this.currentItemIndex}"]`)
                .getBoundingClientRect();
            const containerCenterX = containerBox.x + containerBox.width / 2;
            const chunkCenterX = chunkBox.x + chunkBox.width / 2;
            const scrollDelta = chunkCenterX - containerCenterX;

            if (scrollDelta) {
                TweenLite.to(this.$refs.container, 0.5, {
                    scrollLeft: this.$refs.container.scrollLeft + scrollDelta
                });
            }
        },
        moveToPreviousDigit() {
            if (this.currentDigitIndex > 0) {
                this.selectDigit(this.currentDigitIndex - 1);
            } else if (this.currentItemIndex > 0) {
                this.selectChunk(this.currentItemIndex - 1);
                this.selectDigit(this.currentItem.size - 1);
            }
        },
        moveToNextDigit() {
            if (this.currentDigitIndex < this.currentItem.value.length - 1) {
                this.selectDigit(this.currentDigitIndex + 1);
            } else if (this.currentItemIndex < this.items.length - 1) {
                this.selectChunk(this.currentItemIndex + 1);
                this.selectDigit(0);
            }
        },
        setDigit(digit) {
            if (digit === '<') {
                this.moveToPreviousDigit();
                return;
            } else if (digit === '>') {
                this.moveToNextDigit();
                return;
            }
            this.module.dispatch('quiz/setDigit', {
                itemIndex: this.currentItemIndex,
                digitIndex: this.currentDigitIndex,
                value: '' + digit
            });

            this.moveToNextDigit();
        }
    }
};
</script>

<style scoped lang="scss">
.SequentialInput {
    display: flex;
    flex-direction: column;
}
.title {
    text-align: center;
    font-size: 120%;
}
.progressText {
    margin-top: 0.5em;
    color: rgba(white, 0.5);
    text-align: center;
}
.container {
    display: flex;
    overflow-x: scroll;
    margin-top: 3em;
    & > * {
        flex-shrink: 0;
    }
}
.chunks {
    display: flex;

    & > * {
        flex-shrink: 0;
    }
}
.padding {
    width: 50%;
}

.Chunk {
    &:not(:first-child) {
        margin-left: 1em;
    }
}

.spacer {
    flex: 1;
}

.showAllContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.editButton {
    align-self: center;
    color: rgba(white, 0.5);
}
</style>
