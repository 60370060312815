<template>
  <div
    class="QuizFeedbackView"
    @click="skipFeedback()">
    <svg
      class="bankvaultSvg"
      xwidth="1596px"
      xheight="1226px"
      viewBox="0 0 1596 1226"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd">
        <g id="Vault-2">
          <path
            id="Vault-Tracks"
            d="M253.99796,216 L274.37021,241.047926 L0,241.047926 L0,216 L253.99796,216 Z M253.99796,909 L274.37021,934.047926 L0,934.047926 L0,909 L253.99796,909 Z M253.99796,300 L274.37021,325.047926 L0,325.047926 L0,300 L253.99796,300 Z M253.99796,994 L274.37021,1019.04793 L0,1019.04793 L0,994 L253.99796,994 Z"
            fill="#1C1C1C" />
          <g
            id="Bank-Vault"
            ref="vault"
            transform="translate(159.000000, 0.000000)">
            <g
              id="Vault"
              transform="translate(211.000000, 0.000000)">
              <circle
                id="Oval-13"
                fill="#383838"
                cx="613"
                cy="613"
                r="613" />
              <circle
                id="Oval-13-Copy"
                fill="#424242"
                cx="613"
                cy="613"
                r="567" />
            </g>
            <g
              id="Holder"
              transform="translate(0.000000, 23.000000)">
              <g id="Door">
                <path
                  id="Path-23"
                  d="M710.706499,1005.21404 C642.046802,1005.21404 595.257855,1005.21404 570.339656,1005.21404 C532.962358,1005.21404 511.450639,987.530444 511.450639,934.311694 C511.450639,881.092944 511.450639,264.946794 511.450639,229.422213 C511.450639,193.897631 541.22672,176.391772 570.339656,176.391772 C589.74828,176.391772 638.925684,176.391772 717.871868,176.391772 L717.871868,21 C495.176518,21 372.787294,21 350.704194,21 C317.579545,21 296.10103,32.7550532 296.10103,63.0890376 C296.10103,93.423022 296.10103,80.4763243 296.10103,108.255337 C296.10103,136.03435 272.099077,154.196459 236.198686,154.196459 C212.265092,154.196459 164.403291,154.196459 92.6132812,154.196459 L92.6132812,334.036803 C158.40136,334.036803 206.06053,334.036803 235.590792,334.036803 C279.886186,334.036803 296.10103,355.376147 296.10103,395.624194 C296.10103,435.872241 296.10103,752.692488 296.10103,794.353654 C296.10103,836.014819 266.837358,848.387866 228.042436,848.387866 C202.179155,848.387866 157.833461,848.387866 95.0053544,848.387866 L95.0053544,1028.36381 C174.283073,1028.36381 223.834269,1028.36381 243.658942,1028.36381 C273.395952,1028.36381 296.10103,1042.72576 296.10103,1076.58318 C296.10103,1110.4406 296.10103,1107.57101 296.10103,1123.30854 C296.10103,1139.04607 302.104936,1160.59294 347.528764,1160.59294 C377.811316,1160.59294 499.993197,1160.59294 714.074407,1160.59294 L710.706499,1005.21404 Z"
                  fill="#333333" />
                <path
                  id="Path-22-Copy-2"
                  d="M0.61328125,824.482422 C0.61328125,824.482422 3.49970841,815 49.2350349,815 C79.7252525,815 95.1846679,818.160807 95.6132813,824.482422 L95.6132812,1053.91089 C91.1749955,1060.96769 75.3416622,1064.49609 48.1132812,1064.49609 C20.8849003,1064.49609 5.05156701,1060.96769 0.61328125,1053.91089 C0.61328125,900.958578 0.61328125,824.482422 0.61328125,824.482422 Z"
                  fill="#2B2B2B" />
                <path
                  id="Path-22-Copy-3"
                  d="M0.61328125,136.482422 C0.61328125,136.482422 3.49970841,127 49.2350349,127 C79.7252525,127 95.1846679,130.160807 95.6132813,136.482422 L95.6132812,365.910889 C91.1749955,372.967692 75.3416622,376.496094 48.1132812,376.496094 C20.8849003,376.496094 5.05156701,372.967692 0.61328125,365.910889 C0.61328125,212.958578 0.61328125,136.482422 0.61328125,136.482422 Z"
                  fill="#2B2B2B" />
                <path
                  id="Path-22"
                  d="M708.613281,993.482422 C708.613281,993.482422 712.392578,984 772.275391,984 C812.197266,984 832.438802,987.160807 833,993.482422 L833,1169.61206 C827.188802,1176.66886 806.457682,1180.19727 770.806641,1180.19727 C735.155599,1180.19727 714.424479,1176.66886 708.613281,1169.61206 C708.613281,1052.1923 708.613281,993.482422 708.613281,993.482422 Z"
                  fill="#2B2B2B" />
                <path
                  id="Path-22-Copy"
                  d="M708.613281,9.48242188 C708.613281,9.48242188 712.392578,1.09232353e-15 772.275391,0 C812.197266,0 832.438802,3.16080729 833,9.48242188 L833,185.612061 C827.188802,192.668864 806.457682,196.197266 770.806641,196.197266 C735.155599,196.197266 714.424479,192.668864 708.613281,185.612061 C708.613281,68.1923015 708.613281,9.48242188 708.613281,9.48242188 Z"
                  fill="#2B2B2B" />
              </g>
            </g>
            <g
              id="Rotate-Wheel"
              ref="wheel"
              transform="translate(523.000000, 275.000000)">
              <circle
                id="Oval-13-Copy"
                fill="#262626"
                cx="302.5"
                cy="338.5"
                r="182.5" />
              <rect
                id="Rectangle-34"
                fill="#898989"
                x="287"
                y="0"
                width="28"
                height="677"
                rx="8" />
              <rect
                id="Rectangle-34"
                fill="#898989"
                transform="translate(301.000000, 338.500000) rotate(-300.000000) translate(-301.000000, -338.500000) "
                x="287"
                y="0"
                width="28"
                height="677"
                rx="8" />
              <rect
                id="Rectangle-34"
                fill="#898989"
                transform="translate(301.000000, 338.500000) rotate(-60.000000) translate(-301.000000, -338.500000) "
                x="287"
                y="0"
                width="28"
                height="677"
                rx="8" />
              <path
                id="Combined-Shape"
                d="M302.5,482 C223.247138,482 159,417.752862 159,338.5 C159,259.247138 223.247138,195 302.5,195 C381.752862,195 446,259.247138 446,338.5 C446,417.752862 381.752862,482 302.5,482 Z M302.5,452 C365.184319,452 416,401.184319 416,338.5 C416,275.815681 365.184319,225 302.5,225 C239.815681,225 189,275.815681 189,338.5 C189,401.184319 239.815681,452 302.5,452 Z"
                fill="#333333" />
              <circle
                id="Oval-13-Copy"
                fill="#262626"
                cx="302"
                cy="339"
                r="11" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';

export default {
    inject: ['module', 'transition', 'theme'],
    computed: {
        session() {
            return this.module.state;
        }
    },
    async mounted() {
        if (this.session.quiz.score === 1) {
            await this.playFeedbackCorrect();
        } else {
            await this.playFeedbackWrong();
        }

        if (this.session.quiz.score === 1) {
            this.transition('LEVEL_SUCCEED');
        } else if (this.session.quiz.lives === 0 || this.session.quiz.timeup) {
            this.transition('LEVEL_FAIL');
        } else {
            this.transition('CONTINUE_PLAY');
        }
    },
    beforeDestroy() {
        if (this.tl) {
            this.tl.kill();
        }
    },
    methods: {
        skipFeedback() {
            if (this.resolveFeedback) {
                this.resolveFeedback();
            }
        },
        playFeedbackWrong() {
            return new Promise(resolve => {
                this.resolveFeedback = resolve;
                // const elementsToAnimate = this.$refs.animateMe
                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(this.$refs.wheel, 4, {
                    rotation: 360,
                    transformOrigin: '50% 50%',
                    ease: Power2.easeInOut
                });

                const shakeSpeed = 0.1;
                const elementsToShake = this.$refs.vault;

                tl.to(elementsToShake, shakeSpeed, {
                    x: '-=50',
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    repeat: 6,
                    x: '+=50',
                    yoyo: true,
                    ease: Power2.easeInOut
                });
            });
        },
        playFeedbackCorrect() {
            return new Promise(resolve => {
                this.resolveFeedback = resolve;
                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(this.$refs.wheel, 4, {
                    rotation: 360,
                    transformOrigin: '50% 50%',
                    ease: Power2.easeInOut
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.QuizFeedbackView {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.bankvaultSvg {
    width: 140%;
    flex-shrink: 0;
}
</style>
