function createLevelOptions({ chunks, ring1 = null, ring2 = null, inner1 = null, inner2 = null }) {
    const complexity = (ring1 ? 1 : 0) + (ring2 ? 1 : 0) + (inner1 ? 2 : 0) + (inner2 ? 2 : 0);
    const itemTime = 5 * complexity;
    const options = {
        chunks,
        itemMemorizeTimeLimit: itemTime,
        itemQuizTimeLimit: itemTime,
        stars2: itemTime * 0.7,
        stars3: itemTime * 0.5,

        ring1,
        ring2,
        inner1,
        inner2
    };
    return options;
}

const rawLevelOptions = [
    createLevelOptions({
        // Level 1.1
        chunks: 5,
        ring1: {}
    }),

    createLevelOptions({
        // Level 1.2
        chunks: 8,
        ring1: {}
    }),

    createLevelOptions({
        // Level 2.1
        chunks: 8,
        ring1: {},
        ring2: { delay: 1 }
    }),

    createLevelOptions({
        // Level 2.2
        chunks: 13,
        ring1: {},
        ring2: { delay: 0 }
    }),

    createLevelOptions({
        // Level 2.2
        chunks: 18,
        ring1: {},
        ring2: { delay: 0 }
    }),

    createLevelOptions({
        // Level 3.1
        chunks: 12,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 1 }
    }),

    createLevelOptions({
        // Level 3.2
        chunks: 15,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 0 }
    }),

    createLevelOptions({
        // Level 3.3
        chunks: 18,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 0 }
    }),

    createLevelOptions({
        // Level 4.1
        chunks: 14,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 1 },
        inner2: { delay: 1 }
    }),

    createLevelOptions({
        // Level 4.2
        chunks: 16,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 0 },
        inner2: { delay: 0 }
    }),

    createLevelOptions({
        // Level 4.3
        chunks: 18,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 0 },
        inner2: { delay: 0 }
    }),

    createLevelOptions({
        // Level 4.4
        chunks: 20,
        ring1: {},
        ring2: { delay: 0 },
        inner1: { delay: 0 },
        inner2: { delay: 0 }
    })
];

export default function getLevelOptions(levelIndex) {
    return rawLevelOptions[levelIndex];
}
