import last from 'lodash-es/last';
import { getRandomInt } from '@/modules/memogenius-game/utils';
import getLevelOptions from './get-level-options';

export default class Session {
    constructor(options) {
        if (options.levelIndex !== undefined) {
            this.levelIndex = options.levelIndex;
            this.options = getLevelOptions(this.levelIndex);
        } else {
            this.options = options;
        }
    }
    generateMemorize() {
        const {
            chunks,
            ring1,
            ring2,
            inner1,
            inner2,
            itemMemorizeTimeLimit,
            items: itemsOptions = null
        } = this.options;
        const memorizeTimeLimit = 1000;

        const items = [];
        if (itemsOptions) {
            itemsOptions.forEach(itemOptions => {
                items.push(itemOptions);
            });
        } else {
            for (let i = 0; i < chunks; i++) {
                let item = {};
                if (ring1) {
                    if (ring1.restrict) {
                        item.ring1 = `${ring1.restrict[getRandomInt(0, ring1.restrict.length)]}`;
                    } else {
                        item.ring1 = `${getRandomInt(0, 9)}`;
                    }
                }
                if (ring2) {
                    if (ring2.restrict) {
                        item.ring2 = `${ring2.restrict[getRandomInt(0, ring2.restrict.length)]}`;
                    } else {
                        item.ring2 = `${getRandomInt(0, 9)}`;
                    }
                }
                if (inner1) {
                    if (inner1.restrict) {
                        item.inner1 = inner1.restrict[getRandomInt(0, inner1.restrict.length)];
                    } else {
                        item.inner1 = `${getRandomInt(0, 9)}${getRandomInt(0, 9)}`;
                    }
                }
                if (inner2) {
                    if (inner2.restrict) {
                        item.inner2 = inner2.restrict[getRandomInt(0, inner2.restrict.length)];
                    } else {
                        item.inner2 = `${getRandomInt(0, 9)}${getRandomInt(0, 9)}`;
                    }
                }
                items.push(item);
            }
        }

        const delays = {};
        if (ring2) {
            delays.ring2 = ring2.delay || 0;
        }
        if (inner1) {
            delays.inner1 = inner1.delay || 0;
        }
        if (inner2) {
            delays.inner2 = inner2.delay || 0;
        }

        return {
            items: items.map(xx => {
                return {
                    timeUsed: 0,
                    data: xx,
                    timeLimit: itemMemorizeTimeLimit
                };
            }),
            timeLimit: memorizeTimeLimit,
            delays
        };
    }
    reportMemorize({ items, timeUsed }) {
        this.memorizeReport = { items, timeUsed };
    }
    generateQuiz() {
        const { chunks, itemQuizTimeLimit } = this.options;
        const quizTimeLimit = itemQuizTimeLimit * chunks;

        const items = this.memorizeReport ? this.memorizeReport.items : this.generateMemorize().items;

        const sourceItems = items.map(item => {
            return {
                quizItemType: 'GameTimemachine-QuizItem',
                data: item.data,
                memorizeTime: item.timeUsed,
                size: (function ({ ring1, ring2, inner1, inner2 }) {
                    return (ring1 ? 1 : 0) + (ring2 ? 1 : 0) + (inner1 ? 2 : 0) + (inner2 ? 2 : 0);
                })(item.data)
            };
        });

        function generateGlobalStartIndexes(items) {
            let i = 0;
            items.forEach(item => {
                item.globalDigitStartIndex = i;
                i += item.size;
            });
        }
        generateGlobalStartIndexes(sourceItems);

        return {
            sourceItems,
            items: sourceItems.map(sourceItem => {
                return {
                    fasit: (function ({ ring1 = '', ring2 = '', inner1 = '', inner2 = '' }) {
                        return ring1 + ring2 + inner1 + inner2;
                    })(sourceItem.data),
                    value: Array.apply(null, Array(sourceItem.size)).map(String.prototype.valueOf, '-'),
                    timeUsed: 0,
                    attempts: [],
                    score: 0,
                    size: sourceItem.size,
                    globalDigitStartIndex: sourceItem.globalDigitStartIndex
                };
            }),
            timeLimit: quizTimeLimit,
            lives: 3
        };
    }
    reportQuiz({ items, timeUsed, score, sourceItems }) {
        const { stars2, stars3, chunks } = this.options;
        const memorizeTime = this.memorizeReport && this.memorizeReport.timeUsed;

        let stars = 0;
        if (score & memorizeTime) {
            if (memorizeTime < stars3 * chunks * 1000) {
                stars = 3;
            } else if (memorizeTime < stars2 * chunks * 1000) {
                stars = 2;
            } else {
                stars = 1;
            }
        }

        this.quizReport = { items, timeUsed, stars, sourceItems };
    }
    generateReport($t) {
        const memorizeReport = this.memorizeReport;
        const quizReport = this.quizReport;
        const items = this.quizReport.items.map((item, i) => {
            const sourceItem = quizReport.sourceItems[i];
            return {
                memorizeTime: sourceItem.memorizeTime,
                quizTime: item.timeUsed,
                initial: {
                    type: 'number',
                    value:
                        item.size > 1
                            ? $t('currentGame.codeSpan', {
                                  start: item.globalDigitStartIndex + 1,
                                  end: item.globalDigitStartIndex + item.size
                              })
                            : $t('currentGame.code', { index: item.globalDigitStartIndex + 1 })
                },
                tests: [
                    {
                        testName: null, // label: 'Number',
                        attempts: item.attempts,
                        fasit: (function () {
                            const lastAttempt = last(item.attempts);
                            if (lastAttempt && lastAttempt.score !== 1) {
                                return {
                                    value: item.fasit
                                };
                            }
                            return null;
                        })()
                    }
                ]
            };
        });
        return {
            memorizeTimeUsed: memorizeReport ? memorizeReport.timeUsed : 0,
            quizTimeUsed: quizReport.timeUsed,
            stars: quizReport.stars,
            items
        };
    }

    generateSessionOptionsForRetry() {
        return Object.assign({}, this.options, {});
    }
}
