<template>
  <div
    class="Digit"
    :class="{ focus, empty: digit === '-' }">
    <span class="content">
      {{ digit === '-' ? placeholder : digit }}
    </span>
  </div>
</template>

<script>
export default {
    props: {
        digit: {
            type: String,
            required: true
        },
        focus: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '?'
        }
    }
};
</script>

<style scoped lang="scss">
.Digit {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(black, 0.1);
    border-radius: 0.5em;
    width: 2em;
    height: 2.5em;
    color: rgba(white, 0.8);

    &.empty {
        color: rgba(white, 0.1);
    }

    &.focus {
        position: relative;
        &::after {
            display: block;
            position: absolute;
            content: '';
            bottom: 3px;
            width: 50%;
            height: 2px;
            background-color: rgba(white, 0.5);
            animation: pulseColor 1s infinite;

            @keyframes pulseColor {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
        }
    }
}
.content {
    font-size: 150%;
}
</style>
