import Session from './Session';
import MemorizeView from './views/MemorizeView';
import QuizView from './views/QuizView';
import QuizFeedbackView from './views/QuizFeedbackView';
import quizModule from './quiz-module';
import { machine } from './machine';

export default {
    moduleName: 'moduleGameBankvault',
    Session,
    quizModule,
    internalRouterOptions: {
        MemorizeView,
        QuizView,

        extraRoutes: [
            {
                path: '/session/quiz/feedback',
                // name: 'quiz',
                component: QuizFeedbackView
            }
        ]
    },
    machine
};
